import { PayPalButton } from "react-paypal-button-v2";
import React, { Component } from 'react';
import CartService from '../CartService';
import apiResolver from '../apiResolver';

export default class PayPalCheckOut extends Component {

  constructor(props) {
    super(props);
    this.processOrderCallback = this.processOrderCallback.bind(this);
    this.getTotalAmount = this.getTotalAmount.bind(this);
    this.getItems = this.getItems.bind(this);
    this.saveOrderInfo = this.saveOrderInfo.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  } 

  saveOrderInfo(details, data, email) {
    var totalAmount = CartService.getTotalAmount();
    var currentOrder = {
      order_date: details.create_time,
      order_email: email,
      paypal_order_id: details.id,
      order_total: totalAmount === 0 ? 0.01 : totalAmount,
      order_name: 'Order',
      order_status: details.status,
      order_payer_id: data.payerID,
    };
    console.log(currentOrder);

    const requestOptions = {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json', 'Access-Control-Request-Method': '*', 'Access-Control-Request-Headers': '*'},
      headers: { 'Content-Type': 'text/plain', 'Access-Control-Request-Method': '*', 'Access-Control-Request-Headers': '*' },
      body: JSON.stringify(currentOrder)
    };

    var url = apiResolver() + 'order/create.php';
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => alert(data));
  }

  sendEmail(email) {
    var attachmentItems = [];
    var cartItems = CartService.getItems();
    for(var i = 0; i < cartItems.length; ++i) {
      attachmentItems.push({ file_name: cartItems[i].name + cartItems[i].filePath.substring(cartItems[i].filePath.lastIndexOf(".")), file_url: cartItems[i].filePath })
    }

    const attachmentRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'text/plain', 'Access-Control-Request-Method': '*', 'Access-Control-Request-Headers': '*' },
      body: JSON.stringify(attachmentItems)
    };
    console.log(attachmentItems);
    console.log(JSON.stringify(attachmentItems));

    var emailUrl = apiResolver() + 'services/mailer.php?email=' + email;
    //var emailUrl = 'https://ec2-18-217-18-162.us-east-2.compute.amazonaws.com/phprestapi/services/mailer.php?email=' + email;
    //var emailUrl = 'https://mattharpdev.com/restapi/services/mailer.php?email=' + email;
    fetch(emailUrl, attachmentRequestOptions)
      .then(response => response.json())
      .then(data => alert(data));
  }

  processOrderCallback(details, data) {
    let completeCallback = this.props.completionCallback;
    let email = this.props.deliveryEmail;

    this.saveOrderInfo(details, data, email);
    this.sendEmail(email);

    CartService.emptyCart();
    // // OPTIONAL: Call your server to save the transaction
    // return fetch("/paypal-transaction-complete", {
    //   method: "post",
    //   body: JSON.stringify({
    //     orderID: data.orderID
    //   })
    // }).then(response => console.log(response.text()));

    alert("Transaction completed successfully! An email has been sent to " + email + " with the purchased products. Thank you for your order!")
    completeCallback();
  }

  getTotalAmount() {
    return CartService.getTotalAmount();
  }

  getItems() {
    return CartService.getItems();
  }

  render() {
    let orderCallback = this.processOrderCallback;
    let totalAmount = CartService.getTotalAmount();

    return (
      <PayPalButton
        amount={totalAmount}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          orderCallback(details, data);
        }}
        options={{
          //clientId: "AVR9IaVTvMLAPcwIdgfamx47kf6wl8Sd_tDNDhOHJ1IYdrUewN9rRNPnbjorkhtewHozbOak3Q2F9yAo"
          clientId: "ARfpud8uDTSIrrFabiilNAfrAYCMoxrPWqNpDBrRmvj3n6_tvf_e-dA0qwlMk2ALibxIgwYJWiBUMpIB"
        }}
      />
    );
  }
}