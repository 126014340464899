import React, { Component } from 'react';
import LoadingText from '../assets/images/LoadingAnimationText.png';
import LoadingAnimation from '../assets/images/LoadingAnimation.gif';

export class Loading extends Component {
    static displayName = Loading.name;

    render () {
        return (
        <div className="loadingDiv">
            <img className="loadingParagraph" src={LoadingText} alt="not found" />
            <img className="loadingImage" src={LoadingAnimation} alt="not found" />
        </div>
        )
    }
}