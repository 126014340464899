import React from 'react';
import { Loading } from '../components/Loading';
import apiResolver from '../apiResolver';

export class Endorsements extends React.Component {
    static displayName = Endorsements.name;

    constructor(props) {
        super(props);
        this.state = {
            endorsements: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateEndorsements();
    }

    static renderEndorsements(endorsements) {
        return (
            <div className="endorsementsContainer">
                <div className="endorsementsSetContainer">
                    {endorsements.map(endorsement =>
                            <div className="endorsementsDiv" key={endorsement.endorsement_id}>
                                <div>
                                    <img alt='endorsement icon' src={endorsement.company_logo_url} className='endorsementImg' />
                                </div>
                                <div>
                                    <h3>{endorsement.company_name}</h3>
                                    <p>{endorsement.description}</p>
                                    <a href={endorsement.company_url} target="_blank">{endorsement.company_url}</a>
                                </div>
                            </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Loading></Loading>
            : Endorsements.renderEndorsements(this.state.endorsements);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateEndorsements() {
        var url = apiResolver() + 'endorsement/read.php';
        const res = await fetch(url);
        if(!res.ok) {
            throw new Error(res.status);
        }
        let data = await res.json();
        console.log(data);
        this.setState({ endorsements: data, loading: false });
    }
}