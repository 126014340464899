import React from 'react';

export class CartItem extends React.Component {
    static displayName = CartItem.name;

    constructor() {
        super();
        this.state = {
            count: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() { 
        this.setState({ count: this.props.item.count });
    }

    handleChange(event) {
         this.setState({ count: event.target.value });
    }

    render() {
        let cartItem = this.props.item;
        let deleteCallback = this.props.removeItemCallback;

        return (
            <div className="cartItemCardContainer">
                <div>
                    <img alt='item icon' src={cartItem.artworkFilePath} className='transcriptionImg' />
                </div>
                <div>
                    <h3>{cartItem.name}</h3>
                    {/* <span className="cartItemSpan">Quantity: <input type="text" className="cartItemQuantityInput" value={this.state.count} onChange={this.handleChange} /></span> */}
                    <span className="cartItemSpan">Quantity: {this.state.count}</span>
                    <button onClick={() => deleteCallback(cartItem.id) }>Remove Item</button>
                </div>
            </div>
        );
    }
}