import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Music } from './components/Music';
import { Shows } from './components/Shows';
import { News } from './components/News';
import { Transcriptions } from './components/Transcriptions';
import { Endorsements } from './components/Endorsements';
import { Cart } from './components/Cart';
import 'bootstrap/dist/css/bootstrap.min.css';

import './custom.css';
import './App.css';

export default class App extends Component {
  static displayName = App.name;

  constructor() {
      super();
      this.state = {
          loading: true
      };
  }

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/music' component={Music} />
        <Route path='/shows' component={Shows} />
        <Route path='/news' component={News} />
        <Route path='/transcriptions' component={Transcriptions} />
        <Route path='/endorsements' component={Endorsements} />
        <Route path='/cart' component={Cart} />
      </Layout>
    );
  }
}