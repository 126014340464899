import React from 'react';
import { Loading } from '../components/Loading';
import { Album } from '../components/Album';
import apiResolver from '../apiResolver';

export class Music extends React.Component {
    static displayName = Music.name;

    constructor() {
        super();
        this.state = {
            albums: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateTracks();
    }

    static renderMusic(albums) {
        return (
            <div className="musicContainer">
                <div className="tracksContainer">
                        {albums.map(album =>
                            <Album albumObject={album}></Album>
                        )}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Loading></Loading>
            : Music.renderMusic(this.state.albums);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateTracks() {
        var url = apiResolver() + 'album/read.php';
        const res = await fetch(url);
        if(!res.ok) {
            console.log(res.status);
            throw new Error(res.status);
        }
        let data = await res.json();
        console.log(data);
        this.setState({ albums: data, loading: false });
    }
}