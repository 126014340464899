import React from 'react';
import AppleWhiteLogo from '../assets/images/icons/AppleLogoWhite.png';
import AmazonWhiteLogo from '../assets/images/icons/AmazonLogoWhite.png';

export class Album extends React.Component {
    static displayName = Album.name;

    constructor() {
        super();
        this.renderPurchasePhysicalCdButton = this.renderPurchasePhysicalCdButton.bind(this);
    }

    renderPurchasePhysicalCdButton(album) {
        if(album.is_single == true)
            return null;
        else
            return (
            <div>
                <button><a href={album.cd_purchase_link} target="_blank">PURCHASE PHYSICAL CD</a></button>
                <button><a href={album.digital_purchase_link} target="_blank">PURCHASE DIGITAL ALBUM</a></button>
            </div>
            )
    }

    render() {
        let album = this.props.albumObject;
        let purchasePhysicalCdButton = this.renderPurchasePhysicalCdButton(album);

        return (
            <div className="albumCardContainer">
                <h4><b>Album: </b>{album.album_name}</h4>
                <h6><b>Release Date: </b>{album.release_date}</h6>
                {purchasePhysicalCdButton}
                {album.tracks.map(track =>
                <div className="musicCardContainer">
                    <iframe src={'https://open.spotify.com/embed/track/' + track.spotify_track_id} width="100%" height="80px" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    <span className="purchaseOptionsSpan">
                        <h6>View/Purchase: </h6>
                        <a target="_blank" href={track.amazon_music_link}><img className="songPurchaseImage" alt="amazon logo" src={AmazonWhiteLogo}/></a>
                        <a target="_blank" href={track.apple_music_link}><img className="songPurchaseImage" alt="apple logo" src={AppleWhiteLogo}/></a>
                    </span>
                </div>
                )}  
            </div>
        )
    }
}