export default class CartService {
    static instance = null;
    static itemsArray = [];
    static callbacks = [];

    static createInstance() {
        var object = new CartService();
        return object;
    }

    static getInstance() {
        if (!CartService.instance) {
            CartService.instance = CartService.createInstance();
        }
        return CartService.instance;
    }

    static getTotalAmount() {
        var total = 0;
        for(var i = 0; i < this.itemsArray.length; ++i) {
          total += (this.itemsArray[i].price * this.itemsArray[i].count);
        }

        return total;
    }

    static updateItemQuantity(itemId, itemType, newItemCount) {
        for(var i = 0; i < this.itemsArray.length; ++i) {
            if(this.itemsArray[i].id == itemId && this.itemsArray[i].itemType == itemType) {
                this.itemsArray[i].count = newItemCount;
            }
        }
    }

    static addItem(itemId, itemName, itemType, itemPrice, artworkFilePath, filePath) {
        var itemToAdd = { 'id': itemId, 'name': itemName, 'type': itemType, 'count': 1, 'price': itemPrice, 'artworkFilePath': artworkFilePath, 'filePath': filePath };
        var itemExistsInArray = false;
        for(var i in this.itemsArray){
            if(this.itemsArray[i].id == itemToAdd.id && this.itemsArray[i].itemType == itemToAdd.itemType) {
                itemExistsInArray = true;
                this.itemsArray[i].count = this.itemsArray[i].count + 1;
            }
        }
        if(!itemExistsInArray)
            this.itemsArray.push(itemToAdd);
        for(var i in this.callbacks){
            this.callbacks[i]();
        }
    }

    static removeItem(itemId) {
        for(var i in this.itemsArray){
            if(this.itemsArray[i].id == itemId){
                this.itemsArray.splice(i, 1);
            }
        }
        for(var i in this.callbacks){
            this.callbacks[i]();
        }
    }

    static emptyCart() {
        this.itemsArray.splice(0, this.itemsArray.length);
        for(var i in this.callbacks){
            this.callbacks[i]();
        }
    }

    static getItems() {
        //alert(this.itemsArray.length);
        return this.itemsArray;
    }

    static setAddOrRemoveItemCallback(callback) {
        this.callbacks.push(callback);
    }

    static cartIsEmpty() {
        return this.itemsArray.length == 0;
    }

    static saveCartOder() {
        
    }
}