import React from 'react';
import { CartItem } from './CartItem';
import CartService from '../CartService';
import PayPalCheckOut from './PayPalCheckOut';

export class Cart extends React.Component {
    static displayName = Cart.name;

    constructor() {
        super();
        this.removeCartItemFunction = this.removeCartItemFunction.bind(this);
        this.emptyCart = this.emptyCart.bind(this);
        this.state = {
            cartItems: CartService.getItems(),
            loading: true,
            itemsDisplay: true,
            infoCollection: false,
            paymentFinalization: false,
            userEmail: ''
        };
        this.handleInputEmailFieldChange = this.handleInputEmailFieldChange.bind(this);
    }

    handleInputEmailFieldChange = event => {
        this.setState({ userEmail: event.target.value });
    }

    removeCartItemFunction(itemId) {
        CartService.removeItem(itemId);
        this.setState({ cartItems: CartService.getItems()});
    }

    emptyCart() {
        CartService.emptyCart();
        this.setState({ cartItems: [] });
    }

    render() {
        const onSuccess = (payment) => {
            console.log("Your payment was succeeded!", payment);
        }	        
        const onCancel = (data) => {
            // User pressed "cancel" or close Paypal's popup! 
            console.log('You have cancelled the payment!', data);
        }	        
        const onError = (err) => {
 // The main Paypal's script cannot be loaded or somethings block the loading of that script! 
            console.log("Error!", err);
// Since the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js" 
// => sometimes it may take about 0.5 second for everything to get set, or for the button to appear			 
        }		            
        let currency = 'USD'; // or you can set this value from your props or state   
        let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout 

        let contents = this.state.cartItems;
        const additionalButtonsStyle = {
            marginLeft: "10px",
        };

        if (this.state.paymentFinalization) {
            return (
                <div className="cartContainer">
                    <div className="cartInnerContainer">
                        <h2 className="cartHeader">Payment</h2>
                        <div className="payPalDivContainer">
                            <PayPalCheckOut deliveryEmail={this.state.userEmail} completionCallback={() => { this.emptyCart(); this.setState({itemsDisplay: true, infoCollection: false, paymentFinalization: false})}}></PayPalCheckOut>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.state.infoCollection) {
            return (
                <div className="cartContainer">
                    <div className="cartInnerContainer">
                        <div className="cartCardContainer">
                            <h2 className="cartHeader">Required Info</h2>
                            <p>Upon final purchase, these items will be delivered to the email address provided below. Please enter the email address where you would like to receive your purchased items.</p>
                            <span><p>Email Address: </p><input onChange={this.handleInputEmailFieldChange} type="text" /></span>
                            <button style={additionalButtonsStyle} onClick={() => {this.setState({itemsDisplay: false, infoCollection: false, paymentFinalization: true }); this.render()}}>Proceed to Payment</button>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="cartContainer">
                    <div className="cartInnerContainer">
                        <h2 className="cartHeader">Cart</h2>
                        <div className="cartCardContainer">
                            
                            {contents.length > 0 
                               ? contents.map(cartItem =>
                                    <div>
                                        <CartItem item={cartItem} removeItemCallback={() => this.removeCartItemFunction(cartItem.id)}></CartItem>
                                    </div>)
                               : <div><p>There are currently no items in your cart.</p></div>
                            }
                        </div>
                        { contents.length > 0 
                            ? <div className="emptyCartContainer">
                                <button onClick={() => {this.setState({itemsDisplay: false, infoCollection: true, paymentFinalization: false}); this.render()}}>Purchase Items</button>
                                <button style={additionalButtonsStyle} onClick={() => this.emptyCart()}>Empty Cart</button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            )
        }
    }
}