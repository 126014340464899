import React, { Component } from "react";

export class Home extends Component {
  static displayName = Home.name;

  constructor() {
    super();
    this.sendTestEmail = this.sendTestEmail.bind(this);
    this.blah = this.blah.bind(this);
  }

  async sendTestEmail() {
    const attachmentRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify([]),
    };

    var emailUrl =
      "https://mattharpmusic.com/restapi/services/mailer.php?email=wildharpo@yahoo.com";
    const res = await fetch(emailUrl, attachmentRequestOptions);
    if (!res.ok) {
      console.log(res.status);
      throw new Error(res.status);
    }
    let data = await res.text();
    console.log(data);
  }

  blah(data) {
    alert(data.text());
  }

  render() {
    return (
      <div className="homeContainer">
        <div className="homeInnerContainer">
          <div className="homeDiv">
            <h3>ABOUT THE ARTIST</h3>
            <p>
              Matt Harp is an accomplished professional guitarist from
              Springfield, Missouri. In the pursuit of his passion for music,
              Matt has managed to amass over two million streams and released
              three solo acoustic fingerstyle albums in the process - "Legacy"
              (2018), "Forward" (2019), and "Attrition" (2021). He participated
              in the inaugural 2019 Fingerstyle Collective Guitar festival and
              placed second at the 2020 Wilson Center Guitar Competition - the
              same year he had the opportunity to share the bill with Lance
              Allen, Spencer Elliott, Alexandr Misko, Maneli Jamal, Thomas Leeb,
              and others as part of the online Fingerstyle Hub Festival series.
              In 2021, Matt's music video for "Festival" was a guest artist
              feature for Candyrat Records - the label that introduced the
              broader world to modern acoustic fingerstyle music. In 2022, his
              song "Michael" was featured in an official Spotify instrumental
              playlist.
              <br />
              <br />
              Matt's playing style is a product of inspiration from his various
              musical influences. His ability to weave together melodies,
              harmonies, and moving bass lines simultaneously - as if multiple
              guitars are being played simultaneously - is a nod to those
              influences. Matt picked up his first guitar at the age of 14, and
              his musical journey has included playing in various groups (rock,
              blues, jazz, metal), solo settings (weddings, concerts), and
              recording studio sessions. He continues to write, record, and
              perform while enjoying life with his family, with plans to release
              additional fingerstyle compositions as well as original jazz and
              fusion pieces.
              <br />
              <br />
              <em>
                For booking inquiries, contact <b>matt@mattharpmusic.com</b>
              </em>
            </p>
            {/* <button onClick={this.sendTestEmail} style={{ float: 'right', opacity: '0' }}>Click!</button> */}
          </div>
        </div>
        {/* <h1>Hello, world!</h1>
            <p>Welcome to your new single-page application, built with:</p>
            <ul>
              <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
              <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
              <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
            </ul>
            <p>To help you get started, we have also set up:</p>
            <ul>
              <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
              <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
              <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
            </ul>
            <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p> */}
      </div>
    );
  }
}
