import React from 'react';
import { Loading } from '../components/Loading';
import apiResolver from '../apiResolver';

export class News extends React.Component {
    static displayName = News.name;

    constructor() {
        super();
        this.state = {
            // blogEntries: [{"blog_entry_id":1,"title":"Video - 'Tranquility' (2019)","subtitle":"Recorded and edited by Audio in Motion","text_content":null,"video_url":"https://www.youtube.com/watch?v=FD8VUR9Fico","image_url":null,"date":"2019-11-22T06:00:00.000Z"},{"blog_entry_id":2,"title":"Video - 'With Feeling' (2019)","subtitle":"Recorded and edited by Audio in Motion","text_content":null,"video_url":"https://www.youtube.com/watch?v=vVKb84pjbpc","image_url":null,"date":"2019-12-17T06:00:00.000Z"}]
            blogEntries: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateBlogEntries();
    }

    // static renderBlogEntries(blogEntries) {
    //     return (
    //         <div className="blogDiv">
    //             {blogEntries.map(blogEntry =>
    //                     <div className="blogDiv">
    //                         <h2>{blogEntry.title}</h2>
    //                         <p>{blogEntry.text_content}</p>
    //                         <iframe className="embeddedVideo" src={blogEntry.video_url} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    //                     </div>
    //             )}
    //         </div>
    //     )
    // }

    static renderItem(item) {
        let isVideoBlogEntry = item.video_url != null;

        if(isVideoBlogEntry)
            return <iframe className="embeddedVideo" src={item.video_url} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        else
            return <img className="blogEntryImg" src={item.image_url} />
    }

    static renderBlogEntries(blogEntries) {
        return (
            <div className="newsContainer">
                <div className="newsSetContainer">
                    {blogEntries.map(blogEntry =>
                            <div className="newsDiv" key={blogEntry.blogEntry_id}>
                                <h3>{blogEntry.title}</h3>
                                <h5>{blogEntry.date}</h5>
                                <p>{blogEntry.text_content}</p>
                                { News.renderItem(blogEntry) }
                            </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Loading></Loading>
            : News.renderBlogEntries(this.state.blogEntries);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateBlogEntries() {
        var url = apiResolver() + 'blog_entry/read.php';
        const res = await fetch(url);
        if(!res.ok) {
            throw new Error(res.status);
        }
        let data = await res.json();
        console.log(data);
        this.setState({ blogEntries: data, loading: false });
    }
}