import React from 'react';
import moment from 'moment';
import { Loading } from '../components/Loading';
import apiResolver from '../apiResolver';

export class Shows extends React.Component {
    static displayName = Shows.name;

    constructor() {
        super();
        this.state = {
            // shows: [{"show_id":1,"venue_name":"The Library Station","venue_address":"2535 N Kansas Expy","venue_city":"Springfield","venue_state_abbreviation":"MO","venue_zip":"65803","venue_website_url":"https://thelibrary.org","cost":0,"description":"Live acoustic fingerstyle originals and covers","date_time":"2020-03-21T19:30:00.000Z"}]
            shows: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateShows();
    }

    static renderShows(shows) {
        return (
            <div className="showsContainer">
                <div className="showsInnerContainer">
                    {shows.map(show =>
                        <div className="showOuterDiv">
                            <div className="showInnerDiv">
                                <h3>{show.venue_name}</h3>
                                <h5 className="showDate">{moment(show.date_time).format("MM/DD/YYYY - hh:mm A")}</h5>
                                <p>{show.description}</p>
                                <a href={show.venue_website_url} target="_blank">{show.venue_website_url}</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Loading></Loading>
            : Shows.renderShows(this.state.shows);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateShows() {
        var url = apiResolver() + 'show/read.php';
        const res = await fetch(url);
        if(!res.ok) {
            console.log(res.status);
            throw new Error(res.status);
        }
        let data = await res.json();
        console.log(data);
        this.setState({ shows: data, loading: false });
    }
}