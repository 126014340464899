import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import CartService from '../CartService';
import './NavMenu.css';
import shoppingCartEmpty from '../assets/images/icons/ShoppingCartIconWhite.png';
import shoppingCartFull from '../assets/images/icons/ShoppingCartIconWhiteFull.png';
import logo from '../assets/images/MattHarpLogoWhite_Landscape.png';
import FacebookIcon from '../assets/images/icons/FacebookIconGrayscale.png';
import InstagramIcon from '../assets/images/icons/InstagramIconGrayscale.png';
import TwitterIcon from '../assets/images/icons/TwitterIconGrayscale.png';
import YouTubeIcon from '../assets/images/icons/YoutubeIconGrayscale.png';
import SpotifyIcon from '../assets/images/icons/SpotifyIconGrayscale.png';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.handleItemClicked = this.handleItemClicked.bind(this);
    this.cartItemAddedCallback = this.cartItemAddedCallback.bind(this);
    this.state = {
      collapsed: TextTrackCue,
      isEmpty: CartService.cartIsEmpty()
    };
    CartService.setAddOrRemoveItemCallback(this.cartItemAddedCallback);
  }

  cartItemAddedCallback() {
      this.setState({ isEmpty: CartService.cartIsEmpty() });
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  handleItemClicked() {
    console.log(this.state.collapsed);
    if (!this.state.collapsed) {
      this.setState({ collapsed: !this.state.collapsed });
    }
  }

  render () {
    let cartEmpty = this.state.isEmpty;

    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand className="text-light" tag={Link} to="/"><img className="navLogo" src={logo} alt="logo" /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/news">News</NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/music">Music</NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/shows">Shows</NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/endorsements">Endorsements</NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <NavLink tag={Link} className="text-light" to="/transcriptions">Transcriptions</NavLink>
                </NavItem>
                 <NavItem onClick={this.handleItemClicked}>
                    <NavLink tag={Link} className="text-light" to="/cart" style={{ marginRight: '40px' }}><img className="cartImg" src={cartEmpty ? shoppingCartEmpty : shoppingCartFull} alt="shopping Cart" /></NavLink>
                </NavItem>
                <NavItem onClick={this.handleItemClicked}>
                  <a title="Facebook" className="navigationIcon" target="_blank" href="https://www.facebook.com/MattHarpMusician/"><img alt="Facebook Icon" src={FacebookIcon} /></a>
                </NavItem>      
                <NavItem onClick={this.handleItemClicked}>
                  <a title="Instagram" className="navigationIcon" target="_blank" href="https://www.instagram.com/mattharpmusic/"><img alt="Instagram Icon" src={InstagramIcon} /></a>
                </NavItem>       
                <NavItem onClick={this.handleItemClicked}>
                  <a title="YouTube" className="navigationIcon" target="_blank" href="https://www.youtube.com/user/wildharpo"><img alt="YouTube Icon" src={YouTubeIcon} /></a>
                </NavItem>     
                <NavItem onClick={this.handleItemClicked}>
                  <a title="Spotify" className="navigationIcon" target="_blank" href="https://open.spotify.com/artist/0Vs2uAHO3EhsL36KUIdDG6"><img alt="Spotify Icon" src={SpotifyIcon} /></a>
                </NavItem>        
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
