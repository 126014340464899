import React from 'react';
import CartService from '../CartService';
import { Loading } from '../components/Loading';
import apiResolver from '../apiResolver';

export class Transcriptions extends React.Component {
    static displayName = Transcriptions.name;

    constructor(props) {
        super(props);
        this.state = {
            // transcriptions: [{"transcription_id":1,"name":"The Hidden Path","file_path":"https://mattharpmusic.com/assets/transcriptions/TheHiddenPath.pdf","price":2,"description":"Complete tablature for the track 'The Hidden Path' from the album 'Legacy' (2018)","purchase_link":null,"transcriber_name":"Matt Harp"},{"transcription_id":2,"name":"Not Today","file_path":"https://mattharpmusic.com/assets/transcriptions/NotToday.pdf","price":3,"description":"Complete tablature for the track 'Not Today' from the album 'Forward' (2019)","purchase_link":null,"transcriber_name":"Matt Harp"},{"transcription_id":3,"name":"Memory","file_path":"https://mattharpmusic.com/assets/transcriptions/Memory.pdf","price":3,"description":"Complete tablature for the track 'Memory' from the album 'Forward' (2019)","purchase_link":null,"transcriber_name":"Matt Harp"}]
            transcriptions: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateTranscriptions();
    }

    static renderTranscriptions(transcriptions) {
        return (
            <div className="transcriptionsContainer">
                <div className="transcriptionsSetContainer">
                    {transcriptions.map(transcription =>
                            <div className="transcriptionsDiv" key={transcription.transcription_id}>
                                <div>
                                    <img alt='transcription icon' src={transcription.artwork_file_path} className='transcriptionImg' />
                                </div>
                                <div className="transcriptionInfoDiv">
                                    <h3>{transcription.name}</h3>
                                    <p>{transcription.description}</p>
                                    <p>{'$' + transcription.price}</p>
                                    <span>
                                        <button onClick={() => {CartService.addItem(transcription.transcription_id, transcription.name, 'transcription', transcription.price, transcription.artwork_file_path, transcription.file_path);}}>Add to Cart</button>
                                    </span>
                                </div>
                            </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Loading></Loading>
            : Transcriptions.renderTranscriptions(this.state.transcriptions);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateTranscriptions() {
        var url = apiResolver() + 'transcription/read.php';
        const res = await fetch(url);
        if(!res.ok) {
            throw new Error(res.status);
        }
        let data = await res.json();
        console.log(data);
        this.setState({ transcriptions: data, loading: false });
    }
}